
import MergeAllComponents from "./Camponents/MergeAllComponents/MergeAllComponents";
function App() {
  return (
    <>
     
      <MergeAllComponents/>
    </>
  );
}

export default App;
