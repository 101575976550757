import React from 'react'
import "./../Ourinformation/Ourinformation.css";
import logo_synergy from "./../../Assets/logo-synergytech.png";
import contect from "./../../Assets/contect-logo.png";
import email from "./../../Assets/email-logo.png";

function Ourinformation() {
  return (
    <div>
       <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-3 col-12 ">
          <img src={logo_synergy} alt="logo-synergytech.png"className="logo_stebal" />
          <p className="paragraf_logo">
            We are strongly focused on providing Business operatios, Consulting
            services and Software solutions.
          </p>
        </div>
        <div className="col-lg-1 col-md-1 col-1 " />

        <div className="col-lg-3 col-md-3 col-12 ">
          <p className="quick_links mt-5">Quick Links</p>

          <ul className="quick_inputs">
            <li>About Us</li>
            <li>Engineering Services</li>
            <li>Business Services</li>
            <li>Career</li>
            <li>Testimonials</li>
          </ul>
        </div>
        <div className="col-lg-1 col-md-1 col-1 " />

        <div className="col-lg-4 col-md-4 col-12 ">
          <p className="contect_us mt-5">Contact Us</p>
          <p className="conterct_paragraf">
            Want Job or Want to Submit some Feedback. Feel Free to Contact
          </p>
          <div className="name_box mt-4 row">
            <img src={contect} className="logo_contect col-1" />
            <input
              className="col-lg-8 col-md-8 col-8 input_style_email"
              placeholder="Name"
            />
          </div>

          <div className="email_box mt-2 row">
            <img src={email} className="logo_email col-1" />
            <input
              className="col-lg-8 col-md-8 col-8 input_style_email"
              placeholder="Email"
            />
          </div>
          <textarea className="simple_box mt-2"></textarea>
          {/* <div className="simple_box mt-2" /> */}
          <div className="small_green_box mt-2 ">
            <button type="button" class="btn btn-green ">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Ourinformation
