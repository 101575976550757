import React from 'react';
import "./../Clientscard/Clientscard.css";
import ellips from "../../Assets/Ellipse-1.png";
import ellip from "../../Assets/Ellipse 2.png";
import ellipes from "../../Assets/Ellipse 3.png";
import left_side_arrow2 from "./../../Assets/left-side-arrow2.png";
import right_side_arrow2 from "./../../Assets/right-side-arrow2.png";
import Slider from "react-slick";
function Clientscard() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <img  src={right_side_arrow2} />,
        prevArrow: <img  src={left_side_arrow2} />,
        autoplay: true,  
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 768,     
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
    
          {
              breakpoint: 590,     
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
           
          {
            breakpoint: 1024,  
            settings: {
              slidesToShow: 2,
              slidesToScroll: 3,
            }
          }
    
          
        ]
      };
  return (

    <div className="container    mb-5">
      

    <div>
      <Slider {...settings}>
      
        <div className="container">
          <div className="card_client">
            <img className="ellipse_image" src={ellips} alt="Ellipse-1.png" />
            <p className="client_name">Tangotag</p>
            <p className="paragraf_client">
              “Imperdiet purus tristique pulvinar mDictum ullamcorper pretium
              mi quis. Vel at pellentesque ligula neque. Amet tincidunt”
            </p>
          </div>
          </div>



          <div className="container">
          <div className="card_client">
            <img className="ellipse_image" src={ellip} alt="Ellipse-2.png" />
            <p className="client_name">Tangotag</p>
            <p className="paragraf_client">
              “Imperdiet purus tristique pulvinar mDictum ullamcorper pretium
              mi quis. Vel at pellentesque ligula neque. Amet tincidunt”
            </p>
          </div>
          </div>



        <div className="container">
          <div className="card_client">
            <img className="ellipse_image" src={ellipes} alt="Ellipse-1.png" />
            <p className="client_name">Tangotag</p>
            <p className="paragraf_client">
              “Imperdiet purus tristique pulvinar mDictum ullamcorper pretium
              mi quis. Vel at pellentesque ligula neque. Amet tincidunt”
            </p>
          </div>
          </div>    

          
          <div className="container">
          <div className="card_client">
            <img className="ellipse_image" src={ellip} alt="Ellipse-1.png" />
            <p className="client_name">Tangotag</p>
            <p className="paragraf_client">
              “Imperdiet purus tristique pulvinar mDictum ullamcorper pretium
              mi quis. Vel at pellentesque ligula neque. Amet tincidunt”
            </p>
          </div>
          </div>


          
        
      </Slider>
    </div>
  </div>
  
  )
}

export default Clientscard;
