import React from 'react'
import "./../FAQ/FAQ.css";
function FAQ() {
  return (
    <div className='main_div3'>
      <div className='faq_color'>
        <p className='faq_input mt-4'>
        FAQ
        </p>
      </div>
    </div>
  )
}

export default FAQ
