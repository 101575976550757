import React from 'react'
import Navbar from "./../Navbar/Navbar";
import Sellyourhouse from "./../Sellyourhouse/Sellyourhouse";
import Howworks from "./../Howworks/Howworks";
import Contect from "./../Contect/Contect";
import Trustedoffer from "./../Trustedoffer/Trustedoffer";
import Ourcompany from "./../Ourcompany/Ourcompany";
import Topdollar from "./../Topdollar/Topdollar";
import Ourclients from "./../Ourclients/Ourclients";
import Clientscard from "./../Clientscard/Clientscard";
import FAQ from "./../FAQ/FAQ";
import Listing from "./../Listing/Listing";
import Locations from "./../Locations/Locations";
import Ourinformation from "./../Ourinformation/Ourinformation";
import logo_synergy from "../../Assets/logo-synergytech.png";
import "./../MergeAllComponents/MergeAllComponents.css";
function MergeAllComponents() {
  return (
    <>
       <nav class="navbar navbar-expand-lg main_div_navbar ">
      <div class="container-fluid ">
        <img src={logo_synergy}  alt="logo_synergy" className="logo_style" />
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
            
            </li>
          </ul>
          <form class="d-flex" role="search">
            <a className="navbar_sell" href="#sellyourhouse">Sell Your House</a>
            <a className="navbar_para" href="#howuitwork">How it Works</a>
            <a className="navbar_para" href="#ourcompany">Our Company</a>
            <a className="navbar_para" href="#review">Reviews</a>
            <a className="navbar_para" href="#faq">FAQ</a>
            <a className="navbar_para" href="#contactus">Contact Us</a>
          </form>
        </div>
      </div>
    </nav>

    <>
    
       

        <div id="sellyourhouse">
        <Sellyourhouse />
        </div>

        <div id="howuitwork">
        <Howworks/>
        </div>

        <div>
        <Contect/>
        </div>

        <div>
        <Trustedoffer/>
        </div>

        <div id="ourcompany">
        <Ourcompany/>
        </div>

        <div>
        <Topdollar/>
        </div>

        <div id="review">
        <Ourclients/>
            </div>
            <div>
            <Clientscard/>
            </div>

            <div id="faq">
            <FAQ/>
            </div>

            <div>
            <Listing/>
            </div>
            <div>
            <Locations/>
            </div>
            <div id="contactus">
            <Ourinformation/>
            </div>
    </>
    </>

  )
}

export default MergeAllComponents
