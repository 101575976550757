import React from "react";
import "./../Contect/Contect.css";
import phonelogo from "../../Assets/Phone-logo.png";
function Contect() {
  return (
    <>
      <div className="back_ground_img">
        <div className="contact_custom">
          <div>
            <p className="call_us_today">CALL US TODAY</p>
          </div>

          <div className="call_box">
            <div>
              <img
                className="front_image"
                src={phonelogo}
                alt="Phone-logo.png"
              />
            </div>
            <div>
              <p className="call_us">CALL US</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contect;
